/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	DocsEmployeeFormDetail,
	fetchEmployeeFormDetails,
	fetchEmployeeFormW4s,
	fetchI9EmployeeForms,
	fetchMIW4FormDetails,
	getDirectDepositForm,
	getElectronicW2ConsentForm,
	I9NonCitizenResponse,
} from 'core/API/documents';
import { useDocumentContext } from 'module/documents/context';
import {
	pruneDirectDepositResponse,
	pruneElectronicW2ConsentResponse,
	removeCommonFormFields,
	sanitizeAPIFormData,
} from 'module/documents/utils';

import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { CommonGetParams } from 'types';

export const useEmployeeFormDetails = (params: CommonGetParams = {}, disabled?: boolean) => {
	return useQuery<DocsEmployeeFormDetail>(
		['employee-form/details', params || ''],
		() => fetchEmployeeFormDetails(params),
		{
			staleTime: Infinity, // Ensures the data does not revalidate until the user reloads the page
			cacheTime: Infinity, // Keeps the data in the cache indefinitely
			refetchOnWindowFocus: true, // Prevents refetching when the window is refocused
			enabled: !disabled,
		}
	);
};

const getNonCitizenDetails = (file_type: 'a' | 'b' | 'c', nonCitizenitem: I9NonCitizenResponse) => {
	if (!nonCitizenitem) return {};

	return {
		[`document_${file_type}_title`]: nonCitizenitem.title,
		[`document_${file_type}_authority`]: nonCitizenitem.authority,
		[`document_${file_type}_number`]: nonCitizenitem.number,
		[`document_${file_type}_expiration_date`]: nonCitizenitem.expiration_date,
		[`document_${file_type}_file`]: nonCitizenitem.employee_file,
	};
};

export const useEmployeeFormData = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { selectedEmployee, resetForm, setLoadedFormIds } = useDocumentContext();

	const email = selectedEmployee?.user.email;

	useEffect(() => {
		if (!email) return;

		setIsLoading(true);

		Promise.all([
			fetchEmployeeFormDetails(selectedEmployee.user.id),
			fetchI9EmployeeForms(selectedEmployee.user.id),
			fetchEmployeeFormW4s(selectedEmployee.user.id),
			fetchMIW4FormDetails(selectedEmployee.user.id),
			getDirectDepositForm(selectedEmployee.user.id),
			getElectronicW2ConsentForm(selectedEmployee.user.id),
		]).then((data) => {
			const [
				employeeFormResponse,
				i9FormResponse,
				w4FormResponse,
				miW4FormResponse,
				directDepositResponse,
				electronicW2ConsentResponse,
			] = data;

			const loadedFormIds: Record<string, number> = {};

			const activeUserDetails = (() => {
				if (!employeeFormResponse) {
					return undefined;
				}

				const prunedDetails = removeCommonFormFields(employeeFormResponse);

				loadedFormIds.employeeFormId = employeeFormResponse?.id;

				console.log('** employeeFormResponse:', employeeFormResponse);

				return {
					...prunedDetails,
					ssn_last_4: employeeFormResponse?.ssn?.slice(-4),
					employee_signature: employeeFormResponse?.employee_signature_file?.id,
					internal_signature_file: employeeFormResponse?.employee_signature_file?.path,
				};
			})();

			const activeI9FormData = (() => {
				if (!i9FormResponse) {
					return undefined;
				}
				const activeUserDetails = i9FormResponse;

				const prunedDetails = removeCommonFormFields(activeUserDetails);

				loadedFormIds.i9FormId = activeUserDetails.id;
				return {
					...prunedDetails,
					...getNonCitizenDetails('a', activeUserDetails.employee_form_i9_not_citizen_a),
					...getNonCitizenDetails('b', activeUserDetails.employee_form_i9_not_citizen_b),
					...getNonCitizenDetails('c', activeUserDetails.employee_form_i9_not_citizen_c),
					employee_form_i9_supplement: null,
					employee_form_i9_supplement_translator: null,
				};
			})();

			const activeW4FormData = (() => {
				if (!w4FormResponse) {
					return undefined;
				}

				const activeUserDetails = w4FormResponse;
				const prunedDetails = removeCommonFormFields(activeUserDetails);

				loadedFormIds.w4FormId = activeUserDetails.id;
				return {
					...prunedDetails,
					head_of_household: Boolean(activeUserDetails.filing_status),
				};
			})();

			const activeMiW4FormResponse = (() => {
				if (!miW4FormResponse) {
					return undefined;
				}

				const activeUserDetails = miW4FormResponse;
				const prunedDetails = removeCommonFormFields(activeUserDetails);

				loadedFormIds.w4FormId = activeUserDetails.id;
				return {
					...prunedDetails,
				};
			})();

			const activeDirectDepositResponse = (() => {
				if (!directDepositResponse) {
					return undefined;
				}
				const prunedDetails = pruneDirectDepositResponse(directDepositResponse);

				loadedFormIds.directDepositId = directDepositResponse.id;
				return {
					...prunedDetails,
				};
			})();

			const activeElectronicW2Consent = (() => {
				if (!electronicW2ConsentResponse) {
					return undefined;
				}
				const prunedDetails = pruneElectronicW2ConsentResponse(electronicW2ConsentResponse);

				loadedFormIds.electronicW2Consent = electronicW2ConsentResponse.id;

				return {
					...prunedDetails,
				};
			})();

			setLoadedFormIds(loadedFormIds);

			resetForm(
				sanitizeAPIFormData({
					...activeUserDetails,
					...activeI9FormData,
					...activeW4FormData,
					...activeMiW4FormResponse,
					...activeDirectDepositResponse,
					...activeElectronicW2Consent,
				})
			);
		});

		setIsLoading(false);
	}, [email]);

	return { isLoading };
};
