import { Box, Typography } from '@mui/material';
import { submitW2ConsentForm, W2ConsentForm } from 'core/API/documents';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import {
	DelayButton,
	FormAlert,
	FormGroupSection,
	SignAndSubmit,
} from 'module/documents/components/components';
import DynamicFormComponent, {
	FieldRenderer,
	GridWrapper,
} from 'module/documents/components/formTabs/DynamicFormComponent';
import { EmployerFields } from 'module/documents/components/formTabs/EmployerFields';
import { useDocumentContext } from 'module/documents/context';
import {
	CommonFieldsFormIndexed,
	W2ConsentFormGroup,
	W2ConsentFormGroupIndexed,
} from 'module/documents/data';
import { filterFields } from 'module/documents/utils';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';

const step1Fields = filterFields(CommonFieldsFormIndexed, {
	include: ['first_name', 'middle_initial', 'last_name', 'email', 'phone'],
});

const managementFields = filterFields(W2ConsentFormGroupIndexed, {
	include: ['entered_email_phone_rasi', 'portal_invite_sent', 'hr_copy_sent'],
});

export const W2Consent = () => {
	const { formik, selectedEmployee } = useDocumentContext();
	const { workplaceId } = useWorkplacesContext();
	const { showToast } = useToastContext();

	const handleSubmit = async () => {
		const payload: W2ConsentForm = {
			consent_electronic_w2: Boolean(formik.values.consent_electronic_w2),
			entered_email_phone_rasi: Boolean(formik.values.entered_email_phone_rasi),
			portal_invite_sent: Boolean(formik.values.portal_invite_sent),
			hr_copy_sent: Boolean(formik.values.hr_copy_sent),
			signature_approved: true,
			workplace: {
				id: workplaceId,
			},
			employee: {
				id: selectedEmployee.user.id,
			},
		};

		try {
			await submitW2ConsentForm(payload, workplaceId);
			showToast('success', 'Form has been submitted!');
		} catch (error: any) {
			console.error('Error submitting form:', error.error);
			alert('Something went wrong while submitting the form.');
		}
	};

	return (
		<>
			<Box mb={2}>
				<Typography variant="h4" mb="24px">
					{W2ConsentFormGroup.title}
				</Typography>
			</Box>

			<Box display="flex" flexDirection="column" gap={2}>
				<FormGroupSection>
					<FormAlert severity="info">
						<strong>Mainstreet Ventures Restaurant Group</strong> is required by the IRS to provide
						each employee with a W-2 Form stating their compensation and tax withholdings for the
						year. You can choose to receive this electronically instead of a paper copy.
					</FormAlert>

					<FormAlert severity="info">
						The benefits of receiving an electronic W-2 include earlier access, reduced risk of loss
						or theft, and the ability to download data into tax preparation software.
					</FormAlert>

					<FormAlert severity="info">
						Employees must consent to receive an electronic W-2 by providing a valid email or phone
						number. This ensures compliance with IRS regulations and demonstrates that the employee
						can access the W-2 electronically.
					</FormAlert>

					<FormAlert severity="info">
						To withdraw your consent, you can send an email or written notice. Upon withdrawal, your
						future W-2s will be issued on paper, but it will not affect those already issued
						electronically.
					</FormAlert>

					<FormAlert severity="info">
						If electronic delivery fails due to technical issues, incorrect login, or an outdated
						email, a paper W-2 will be sent. Ensure you promptly update any contact information with
						the HR department.
					</FormAlert>

					<FormAlert severity="info">
						You will receive an email notification with a subject line{' '}
						<strong>“RASI Employee Portal: Your W2 is available”</strong> by January 31st if you
						choose to receive your W-2 electronically.
					</FormAlert>
				</FormGroupSection>

				<FormGroupSection>
					<FormAlert severity="info">
						If you completed the consent form, you will receive an e-mail notification no later than
						January 31st with the subject line “RASI Employee Portal: Your W2 is available”.
						<br />
						<br />
						Please check one of the options below.
					</FormAlert>

					<DynamicFormComponent>
						<GridWrapper field={W2ConsentFormGroupIndexed.consent_electronic_w2} index={0}>
							<FieldRenderer
								field={W2ConsentFormGroupIndexed.consent_electronic_w2}
								formik={formik}
							/>
						</GridWrapper>
					</DynamicFormComponent>
					<DynamicFormComponent>
						{step1Fields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
			</Box>

			<Box paddingY="24px">
				<SignAndSubmit onSubmit={handleSubmit} formData={W2ConsentFormGroup} />
			</Box>

			<FormGroupSection>
				<FormGroupSection
					title="Employer Information"
					isEmployer={true}
					marginTop="24px"
					marginBottom="12px"
				>
					<EmployerFields fieldValues={['employer_title']} />

					<DynamicFormComponent>
						{managementFields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
				<Box paddingY="8px">
					<DelayButton
						variant="contained"
						onClick={() => {
							formik.setFieldValue('signature_approved', true);
						}}
						style={{ width: 'max-content' }}
					>
						Sign employee certification
					</DelayButton>
				</Box>
			</FormGroupSection>
		</>
	);
};
