import { DocsHRResponse } from 'core/API/documents';
import { useCachedQueryData } from 'core/hooks/useCachedQueryData';
import { IUsers } from 'core/interfaces/userInterface';
import { Formik, FormikProps, useFormik } from 'formik';
import { useWorkplaceUser } from 'module/documents/utils';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { createContext, ReactNode, useContext, useState } from 'react';

// Define the types for the context value
interface DocumentContextType {
	formik: FormikProps<any>;
	resetForm: (newValues: any) => void;
	isEmployer: boolean;
	selectedEmployee: IUsers;
	setSelectedEmployee: React.Dispatch<React.SetStateAction<IUsers>>;
	loadedFormIds: Record<string, any>;
	recordFormSubmission: (formId: string) => void;
	setLoadedFormIds: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

// Create the context
const DocumentContext = createContext<DocumentContextType | null>(null);

// Create a custom hook for using the DocumentContext
export const useDocumentContext = (): DocumentContextType => {
	const context = useContext(DocumentContext);
	if (!context) {
		throw new Error('useDocumentContext must be used within a DocumentProvider');
	}
	return context;
};

const now = new Date().toISOString().split('T')[0];

// Define the provider component
interface DocumentProviderProps {
	children: ReactNode;
	defaultUser: IUsers;
}

export const DocumentProvider = ({ children, defaultUser }: DocumentProviderProps) => {
	const [selectedEmployee, setSelectedEmployee] = useState<IUsers>(defaultUser);
	const [loadedFormIds, setLoadedFormIds] = useState<Record<string, any>>({});

	const { workplace } = useWorkplacesContext();
	const { data: user } = useWorkplaceUser();

	const isEmployer = user.human_resources;
	const hrDetails = useCachedQueryData<DocsHRResponse>('employee-form/hr');

	const defaultHRValues = hrDetails
		? {
				employer_title: hrDetails?.employer_title || '',
				employer_name: hrDetails?.employer_name || '',
				employer_business_name: hrDetails?.employer_business_name || '',
				employer_business_address: hrDetails?.address || '',
				employer_city: hrDetails?.city || '',
				employer_state: hrDetails?.state || '',
				employer_zip_code: hrDetails?.zip_code || '',
				employer_ein: hrDetails?.employer_ein || '',
				employer_signature_path: hrDetails?.signature_file?.path || '',
				employer_signature: hrDetails?.signature_approved || false,
			}
		: {};

	const commonValues = {
		signature_date: now,
		preparer_signature_date: now,
		employer_signature_date: now,
		multiple_jobs_or_spouse_works: 0,
		number_of_children: 0,
		number_of_dependents: 0,
		immigration_status: '1',
		head_of_household: 0,
		translator_assisted: false,
		employee_rehire: false,
		restaurant_name: workplace.name,
	};

	const initialValues = { ...commonValues, ...defaultHRValues };

	const formik = useFormik({
		initialValues,
		validate: (_values) => {
			const errors = {};
			return errors;
		},
		onSubmit: (values) => {
			alert(JSON.stringify(values, null, 2));
		},
	});


	const resetForm = (newValues: any) =>
		formik.resetForm({ values: { ...initialValues, ...newValues } });

	const recordFormSubmission = (formId) => {
		setLoadedFormIds((prev) => ({ ...prev, [formId]: true }));
	}

	return (
		<Formik initialValues={initialValues} onSubmit={console.log}>
			<DocumentContext.Provider
				value={{
					formik,
					resetForm,
					isEmployer,
					selectedEmployee,
					setSelectedEmployee,
					loadedFormIds,
					setLoadedFormIds,
					recordFormSubmission
				}}
			>
				{children}
			</DocumentContext.Provider>
		</Formik>
	);
};
